import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Cookies from 'js-cookie'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            "menu-home": "Home",
            "menu-courses": "Courses",
            "menu-contact": "Contact us",
            "menu-help": "Info",
            "menu-search-placeholder": "Search a course...",
            "footer-address": "Address",
            "footer-phone": "Phone",
            "footer-email": "Email",
            "footer-short-links": "Quick Links",
            "footer-info": "Informations",
            "footer-my-account": "My account",
            "footer-home": "Home",
            "footer-courses": "Courses",
            "footer-contact": "Contact us",
            "footer-profile": "Profile",
            "footer-all-right-reserved": "© Intact Insurance Company, 2023. All rights reserved.",
            "login-page-welcome": "Welcome",
            "login-page-chose-login-type": "Choose which type of connection you want to use as login",
            "login-page-login-type-intact": "Intact or Belairdirect Employee ",
            "login-page-login-type-externe": "External Connection (ICITÉ)",
            "login-page-login-type-externe-portal": "External Connection (Portal Intact)",
            "login-page-return-btn": "Return",
            "login-page-login-to-start": "Log in to start your learning session",
            "login-page-form-email-label": "Email Address",
            "login-page-form-email-req-message": "Please enter your email address!",
            "login-page-form-email-invalid-message": "This is not a valid email address!",
            "login-page-form-password-label": "Password",
            "login-page-form-password-req-message": "Please enter your password!",
            "login-page-form-remember-me": "Remember me",
            "login-page-form-password-forgot-btn": "Forgot your password",
            "login-page-form-login-btn": "Login",
            "forgot-password-page-title": "Forgot your password ?",
            "forgot-password-page-info": "Enter the email address associated with your Icité account",
            "forgot-password-page-form-email-label": "Email Address",
            "forgot-password-page-form-email-req-message": "Please enter your email address!",
            "forgot-password-page-form-email-invalid-message": "This is not a valid email address!",
            "forgot-password-page-form-submit-btn": "Submit",
            "forgot-password-page-form-submit-success": "An email to reset the password has been sent to you",
            "reset-password-page-title": "Reset your password",
            "create-password-page-title": "Create your password",
            "reset-password-page-info": "Enter your new password",
            "reset-password-page-form-password-label": "Password",
            "reset-password-page-form-password-req-message": "Please enter your password!",
            "reset-password-page-form-password-confirm-label": "Password confirmation",
            "reset-password-page-form-password-confirm-placeholder": "Confirm password",
            "reset-password-page-form-password-confirm-req-message": "Please confirm the password!",
            "reset-password-page-form-password-two-not-the-same": "Two passwords you entered are inconsistent!",
            "reset-password-page-form-submit-btn": "Submit",
            "reset-password-page-form-submit-success": "Your password has been successfully reset",
            "reset-password-page-form-login": "Login",
            "reset-password-page-request-not-valid": "This request is not valid",
            "create-password-page-form-password-8caracters": "Password must be at least 8 characters long.",
            "create-password-page-form-password-1capitalize": "Password must contain at least one uppercase letter.",
            "create-password-page-form-password-1specialcaracter": "Password must contain at least one special character.",
            "home-page-course-access-btn": "Access training courses",
            "home-page-advice-title": "Advices For A Successful Training",
            "home-page-advice-subtitle": "SOME EXAMPLES FOR YOU",
            "home-page-advice-narration-title": "Narration",
            "home-page-advice-narration-text": "Use headphones, as the formations contain sound narrations.",
            "home-page-advice-screens-title": "Two screens",
            "home-page-advice-screens-text": "Use your 2 computer screens to project the training on one of them and open the required documents on the other.",
            "home-page-advice-environnement-title": "Workspace",
            "home-page-advice-environnement-text": "Let your colleagues know that you are in training to keep the focus.",
            "home-page-advice-teams-title": "Teams",
            "home-page-advice-teams-text": "If possible, display a “do not disturb” status in Teams during your training.",
            "home-page-courses-title": "Available Courses",
            "home-page-courses-no-available-courses": "No training available",
            "home-page-courses-no-available-courses-contact": "Please contact the Formation et Développement team to add you to the desired training",
            "home-page-see-more-btn": "SEE MORE",
            "calendar-date-from": "from",
            "calendar-date-to": "to",
            "calendar-date-at": "at",
            "course-list-page-title": "List of training courses",
            "course-list-page-search-placeholder": "What is your research? (Training name or Keywords)",
            "course-list-page-search-results": "Search results",
            "course-list-page-no-search-results": "No search results",
            "course-list-page-no-available-courses-broker": "No training available yet. Your courses will be available later this year 2021",
            "course-list-page-no-available-courses": "No training available",
            "course-list-page-no-available-courses-contact": "Please contact the Formation et Développement team to add you to the desired training",
            "course-list-page-recent-courses-viewed": "Recently viewed",
            "course-list-advanced-search": "Search in modules",
            "contact-page-title": "CONTACT US",
            "contact-page-write-us": "Contact to us",
            "contact-page-write-us-subtitle": "ALWAYS READY TO ANSWER YOU",
            "contact-page-form-subject": "Subjet",
            "contact-page-form-message": "Message",
            "contact-page-form-send-message": "Submit your message",
            "contact-page-form-send-message-success": "Message sent successfully !",
            "contact-page-form-send-message-fail": "An error has occurred. The message could not be sent",
            "help-page-title": "INFO",
            "help-page-write-us": "Write to us",
            "help-page-write-us-subtitle": "ALWAYS READY TO ANSWER YOU",
            "help-page-form-subject": "Subjet",
            "help-page-form-message": "Message",
            "help-page-form-send-message": "Submit your message",
            "help-page-form-send-message-success": "Message sent successfully !",
            "help-page-form-send-message-fail": "An error has occurred. The message could not be sent",
            "help-page-student-title": "Useful information about the Icité platform and some tips for an optimal experience",
            "help-page-student-sub-title": "Icité offers a unique learning experience to the student.",
            "help-page-student-item-1": "What is Icite?",
            "help-page-student-item-2": "How track my progress?",
            "help-page-student-item-3": "How add personal notes?",
            "help-page-student-item-4": "How download my documents?",
            "help-page-student-item-5": "Why is my activity not completed?",
            "help-page-student-item-6": "How do I pass my exam?",
            "help-page-student-item-7": "How clear browsing history?",
            "help-page-student-item-8": "What other information should you pay attention to?",
            "help-page-student-item-10": "",
            "help-page-student-item-11": "",
            "help-page-student-item-and-more": "And more",
            "help-page-student-item-contact": "If you have any questions or need support, please contact the \n \"Formation et Développement Intact\" team using the Contact page.",
            "help-page-student-item-bouton-contact": "Contact Us",
            "profil-page-profil-type": "Profile type",
            "profil-page-affiliation": "Affiliation",
            "profil-page-member-since": "Member since",
            "profil-page-modify-password": "Change your password",
            "profil-page-create-password": "Create or change your password",
            "profil-page-logout": "Logout",
            "profil-reset-password-page-title": "Password change",
            "profil-reset-password-page-return-btn": "Return",
            "profil-reset-password-page-password-info": "If you don't have a password, use the section below to create it and be able to log in on any personal device.",
            "profil-reset-password-page-password-label": "Password",
            "profil-reset-password-page-password-req-message": "Please enter your password",
            "profil-reset-password-page-confirm-password-label": "Password confirmation",
            "profil-reset-password-page-confirm-password-placeholder": "Confirm password",
            "profil-reset-password-page-confirm-password-req-message": "Please confirm you password",
            "profil-reset-password-page-form-password-two-not-the-same": "Two passwords you entered are inconsistent!",
            "profil-reset-password-page-send": "Submit",
            "profil-reset-password-page-reset-success": "Your password has been successfully reset",
            "profil-reset-password-page-return-to-profile": "Return to profile",
            "course-intro-page-slides-number": "slides",
            "course-intro-page-start-btn": "Start the course",
            "course-intro-page-course-content-btn": "Course content",
            "course-intro-page-course-content-title": "Course content",
            "course-intro-page-course-documents-btn": "Documents",
            "course-intro-page-course-documents-title": "Course Documents",
            "course-intro-page-course-documents-cohorte-title": "Cohort Documents",
            "course-intro-page-course-no-documents-available": "No documents available",
            "course-intro-page-course-virtual-meeting-required": "Virtual meeting required!",
            "course-intro-page-course-virtual-meeting-suggested-optionnal": "Virtual meeting suggested!",
            "course-intro-page-course-virtual-meeting-suggested": "Webinar registration!",
            "course-intro-page-course-virtual-meeting-confirmed": "Registration successfully completed!",
            "course-intro-page-course-virtual-meeting-required-btn": "Join",
            "course-intro-page-course-virtual-meeting-suggested-btn": "Join",
            "course-intro-page-course-virtual-meeting-btn-label": "Join",
            "course-intro-page-course-virtual-meeting-continue-btn-label": "Continue",
            "course-intro-page-course-virtual-meeting-calendar-btn": "Calendar",
            "course-intro-page-course-virtual-meeting-list-btn": "List",
            "course-play-page-return-btn": "Return",
            "course-play-page-progress-done": "completed",
            "course-play-page-search-btn-label": "Search an activity",
            "course-play-page-documents-btn-label": "Course documents",
            "course-play-page-welcome": "Welcome !",
            "course-play-page-welcome-start-btn": "Start the course",
            "course-play-page-welcome-download-documents-btn": "Download documents",
            "course-play-page-addition-information-title": "Additional information",
            "course-play-page-menu-prerequis-text-module-label": "Modules",
            "course-play-page-menu-prerequis-text": "The modules listed below (see numbers) must be completed before starting this module",
            "course-play-page-course-end": "End of course",
            "course-play-page-course-end-congrats": "Congratulations !",
            "course-play-page-course-end-congrats-done": "You have completed the course",
            "course-play-page-course-end-congrats-done-scorm": "Congratulations you have completed the activity",
            "course-play-modules-prev-btn": "Previous",
            "course-play-modules-next-btn": "Next",
            "course-play-modules-confirm-choice-btn": "Confirm choice",
            "course-play-modules-next-task-btn": "Go to the next activity",
            "course-play-modules-return-to-menu-btn": "End of course (return to menu)",
            "course-play-modules-replay-video-btn": "Replay video",
            "course-play-modules-next-question-btn": "Next question",
            "course-play-modules-good-answer": "Good answer!",
            "course-play-modules-wrong-answer": "Wrong answer",
            "course-play-modules-wrong-answer-chose-another": "Please choose another answer",
            "course-play-modules-your-score": "Your Score",
            "course-play-modules-attempts": "Attempts",
            "course-play-modules-attempts-tries": "tries",
            "course-play-modules-explanation": "Explanation",
            "course-play-modules-update-my-answer": "Update my answer",
            "course-play-modules-compare-with-good-answer": "Compare with the good answer",
            "course-play-modules-only-first-attempt-counts": "Only the first attempt of each question counts",
            "course-play-modules-only-last-answer-of-each-question-is-saved": "Only the last answer of each question is saved",
            "course-play-modules-click-below-button-to-skip-question": "Click on the button below to go to the next question",
            "course-play-modules-click-below-button-to-skip-task": "Click on the button below to go to the next activity",
            "course-play-modules-stats-percentage-result": "Result",
            "course-play-modules-stats-percentage-score": "Score",
            "course-play-modules-you-suceeded": "You succeeded !",
            "course-play-modules-congrats-points": "Congratulations! You finished with a total score of",
            "course-play-modules-congrats-skip-and-continue": "Click the button below to continue answering the remaining questions",
            "course-play-modules-unfortunate-fail-points": "Sorry! You finished with a total score of",
            "course-play-modules-close-window": "Close this window",
            "course-play-modules-close-exercice-keep-on-going": "Click the button below to see last question feedback",
            "course-play-modules-exam-intruction-title": "INSTRUCTIONS",
            "course-play-modules-exam-intruction-passing-score": "The passing score is",
            "course-play-modules-exam-intruction-only-first-attempt": "Only the first completed attempt counts and will be recorded",
            "course-play-modules-exam-intruction-you-have": "You have",
            "course-play-modules-exam-intruction-you-have-hours": "hour(s) to complete the exam.",
            "course-play-modules-exam-intruction-you-have-hour": "hour to complete the exam.",
            "course-play-modules-exam-intruction-no-save": "If you exit this panel, the exam will not be saved",
            "course-play-modules-exam-intruction-when-done-submit": "When you are finished, press the 'submit' button to save your results",
            "course-play-modules-exam-intruction-revision-btn": "Revision attempt",
            "course-play-modules-exam-intruction-start-btn": "To start your exam, Click here",
            "course-play-modules-exam-intruction-good-luck": "Good luck !",
            "course-play-modules-exam-submit-btn": "Submit exam",
            "course-play-modules-exam-end-success": "You succeeded !",
            "course-play-modules-exam-end-fail": "You have failed",
            "course-play-modules-exam-end-passing-score": "The passing score is",
            "course-play-modules-exam-end-your-score": "Your final score is",
            "course-play-modules-exam-remaining-time": "Remaining time",
            "course-play-modules-exam-time-ended": "Time over!",
            "course-play-modules-exercice-ended-congrats": "Congratulations, you have now completed the exercise.",
            "course-play-modules-exercice-ended-score": "Your score is",
            "course-play-modules-select": "Select a",
            "our-numbers-title": "When our numbers speak for themselves",
            "our-numbers-students": "Registered students",
            "our-numbers-certificates": "Certificates awarded",
            "our-numbers-trainings": "Courses offered online",
            "start-now-title": "Start now",
            "start-now-subtitle": "Click on the button below to see the courses list",
            "start-course-access-btn": "Access training courses",
            "course-preview-read-more": "Read more",
            "course-preview-read-less": "Read less",
            "course-preview-see-course": "View training",
            "course-student-guide-pdf-main-title-1": "Student Guide (Notes et Documents)",
            "course-student-guide-pdf-loading": "Loading Student Guide...",
            "course-student-guide-pdf-download": "Download Student Guide (Notes and Documents)",
            "general-texts-explication": "Explanation",
            "general-texts-consigne": "Guideline",
            "autoEvaluation1ModuleConfirmChoice": "Confirm choice and",
            "autoEvaluation1ModuleGoToNextQuestion": "go to next question",
            "calculationModuleYourAnswer": "Your answer",
            "calculationModuleGoodAnswer": "The good answer is",
            "calculationModuleConfirm": "Confirm",
            "moreQuestionsYesOrNoYes": "Yes",
            "moreQuestionsYesOrNoNo": "No",
            "overviewExamplesQuestions": "Sample questions",
            "satisfactionVeryDissatisfied": "Very dissatisfied",
            "satisfactionDissatisfied": "Dissatisfied",
            "satisfactionSatisfied": "Satisfied",
            "satisfactionNeutral": "Neutral",
            "satisfactionVerySatisfied": "Very satisfied",
            "coursePreviewAccessStart": "Access start : ",
            "coursePreviewAccessEnd": "End of access : ",
            "dashboardContainerFirstVisit": "First Visit ?",
            "dashboardContainerMessageFirstVisit": "If this is your first visit, do not hesitate to visit the INFO page to find out more about the Icité platform and to learn how to properly use the various features.",
            "dashboardContainerVisitInfoPage": "Visit INFO page",
            "close": "Close",
            "dashboardContainerStartAccessError": "You don't have access yet.\n Your access will start from this date: \n",
            "dashboardContainerEndAccessError": "You no longer have access.\n Your access ended on this date: \n",
            "dashboardContainerWarningBetterExperience": "For a better experience please copy the link and open the site on the Chrome browser",
            "dashboardContainerHaveAGoodSession": "Have a good training session.",
            "dashboardContainerGoodNight": "Good Night",
            "dashboardContainerGoodMorning": "Good morning",
            "dashboardContainerHello": "Hello",
            "dashboardContainerGoodMorning2": "Good morning",
            "dashboardContainerGoodLunch": "Good lunch",
            "dashboardContainerGoodAfterNoon": "Good afternoon",
            "dashboardContainerGoodEvening": "Good evening",
            "dashboardContainerFr/Eng": "FR",
            "dashboardContainerLogout": "Logout",
            "dustbinRelease": "Release",
            "dustbinDragYourChoice": "Drag your choice here",
            "examModulePinLater": "Pin for later",
            "examModuleUnpin": "Unpin",
            "examModulePin": "Pin",
            "examModuleSubmitExam": "Submit exam",
            "examModuleSubmitExamExplination": "Click on the Submit button if you have completed and want to send your exam",
            "examModuleCancel": "Cancel",
            "examModuleSubmit": "Submit",
            "examMultipleChoiceModulePinLater": "Pin for later",
            "examMultipleChoiceModuleUnPin": "Unpin",
            "examMultipleChoiceModulePin": "Pin",
            "examMultipleChoiceModuleSubmitExam": "Submit exam",
            "examMultipleChoiceModuleSubmitExamExplanation": "Click on the Submit button if you have completed and want to send your exam",
            "examMultipleChoiceModuleCancel": "Cancel",
            "examMultipleChoiceModuleSubmit": "Submit",
            "exerciceModuleWarning": "Warning",
            "exerciceModuleIsTimed": "This exercise is timed",
            "exerciceModuleIsTimedExplnation1": "You will have",
            "exerciceModuleIsTimedExplnation2": " min per question. If the time runs out, the answer will be considered incorrect",
            "exerciceModuleStart": "Start",
            "exerciceModuleTimeEnded": "Time is over !",
            "exerciceOpenQuestionModuleChoice": "Please choose one of the choices above before writing an answer in this field",
            "exerciceOpenQuestionModuleEntrerYourAnswer": "Enter your answer here",
            "exerciceOpenQuestionConfirm": "Confirm answer and",
            "exerciceOpenQuestionModulegoNextQuestion": "go to next question",
            "coursePlayClickOnIcon": "Click on this icon to open the menu",
            "coursePlayClose": "Close",
            "documentSlideCntDocumentsDownload": "Documents to download",
            "meetingReservationConfirmUnSubscription": "Please confirm the unsubscription to the meeting",
            "meetingReservationConfirm": "Confirm",
            "meetingReservationCancel": "Cancel",
            "meetingReservationUnsubscriptionSuccess": "Unsubscribe successfully to the meeting",
            "meetingReservationClose": "Close",
            "meetingReservationMandatoryMeeting1": "To continue the course, you need participate before to a virtual meeting.",
            "meetingReservationMandatoryMeeting2": "",
            "meetingReservationInscriptionDone": "You are registered at this Webinar, an invitation has been sent to you by email to add it to your agenda.",
            "meetingReservationTeamsLinkAvailable": " The Teams link will be available 30 minutes before the start of the meeting.",
            "meetingReservationMeetingTime": "Date and time of the meeting",
            "meetingReservationPresenceConfirm": "Your presence at the meeting has been confirmed !",
            "meetingReservationClickOnLinkToAccessMeeting": "Please click on the link below to join the meeting :",
            "meetingReservationlinkUnavailable": "No link available at this time.",
            "meetingReservationClickToParticipateWebinar": "Click here to join the webinar",
            "meetingReservationClickToParticipateMeeting": "Click here to join the meeting",
            "meetingReservationConfirmNowregistered1": "You are registered for this",
            "meetingReservationConfirmNowregistered2": "Webinar",
            "meetingReservationConfirmNowregistered3": "meeting",
            "meetingReservationConfirmNowregistered4": ", an invitation has been sent to you by email to add it to your agenda.",
            "meetingReservationConfirmNowregistered5": "The Teams link will be available 30 minutes before the meeting starts.",
            "meetingReservationIsConfirmActiveDate": "Date and time of meeting",
            "meetingReservationIsConfirmActiveEditMeeting": "Modify the selected meeting",
            "meetingReservationIsConfirmActiveUnsubscribe": "Unsubscribe",
            "meetingReservationThanskForParticipationWebinar": "Thank you for participate to the webinar which took place on ",
            "meetingReservationThanskForParticipationMeeting": "Thank you for participate to the meeting which took place on ",
            "meetingReservationToModifyMeeting": "To change the meeting date...",
            "meetingReservationToSubscribe": "To join the meeting...",
            "meetingReservationIsRencontreAmbassadeursSelectDate": "Choose a date and time from the list or the calendar below :",
            "meetingReservationPlaceholder": "Select a date ↓",
            "meetingReservationCancelModification": "Cancel modification",
            "meetingReservationMeeting": "The meeting",
            "meetingReservationTakePlace": "will take place",
            "meetingReservationSubscribeToMeeting": "Subscibe",
            "meetingReservationEndBloc5Part1": "At the end of block #5, you can register for the optional",
            "meetingReservationEndBloc5Part2": "Webinar: Back to the more advanced notions of Contact AP",
            "meetingReservationNoMeetingAvailable": "No meeting available at this time !",
            "meetingReservationWaitAMeetingTryAgain": "Please wait for a new available meeting and try again.",
            "meetingReservationContinueWithoutTakingAMeeting": "To continue the course without participating at a virtual meeting, please click the button below :",
            "meetingReservationContinue": "Continue",
            "meetingReservationSubscribeLater": "I don't want to register now",
            "meetingReservationContinueWithSubscribeLater": "Click below to continue the course without registering, you can come back later to subscribe.",
            "meetingReservationContinueTraining": "Continue training",
            "error": "An error has occurred",
            "help-page-student-item-1-description-1": "Icité is a platform used for integration and level change courses, including:",
            "help-page-student-item-1-description-2": "Clean design, attractive and ergonomic design.",
            "help-page-student-item-1-description-3": "Easy and intuitive navigation.",
            "help-page-student-item-1-description-4": "Progression system for good course continuity.",
            "help-page-student-item-1-description-5": "Multiple integrated modules (exercises, exams, videos, etc.)",
            "help-page-student-item-2-description-1": "Track your overall progress along the route with the percentage indication:",
            "help-page-student-item-2-description-2": "Do the activities in the order proposed. The menu on the left allows you to consult the list of modules of the course.",
            "help-page-student-item-2-description-3": "For some activities, a result is displayed.",
            "help-page-student-item-2-description-4": "Advice",
            "help-page-student-item-2-description-5": "As you progress, redo the less successful exercises.",
            "help-page-student-item-3-description-1": "Personal notes are only visible to you.",
            "help-page-student-item-3-description-2": "Notes are saved automatically when you close the window.",
            "help-page-student-item-3-description-3": "Advice",
            "help-page-student-item-3-description-4": "Write down your questions in anticipation of the next intervention/virtual classroom with your trainer.",
            "help-page-student-item-5-description-1": "Make sure you complete each activity (for example, use the button ",
            "help-page-student-item-5-description-2": "Exit module",
            "help-page-student-item-5-description-3": "or",
            "help-page-student-item-5-description-4": "Exit",
            "help-page-student-item-5-description-5": ", click",
            "help-page-student-item-5-description-6": "Go to next activity",
            "help-page-student-item-5-description-7": "Submit",
            "help-page-student-item-5-description-8": "I have finished typing !",
            "help-page-student-item-6-description-1": "Advice",
            "help-page-student-item-6-description-2": "Pin questions to come back later to not impact your exam time.",
            "help-page-student-item-7-description-1": "If a certain problem persists locally on your device, delete your browser's cache memory.",
            "help-page-student-item-7-description-2": "Deleting the cache memory (or history or temporary internet files) of your internet browser can solve access or display problems on some websites.",
            "help-page-student-item-7-description-3": "Here is how to proceed depending on the internet browser used :",
            "help-page-student-item-7-1": "Chrome",
            "help-page-student-item-7-1-description-1": "Menu icon › Settings › Advanced settings › Privacy and security - Clear browsing data ...",
            "help-page-student-item-7-1-description-2": "Period: All periods.",
            "help-page-student-item-7-1-description-3": "Check Cached images and files.",
            "help-page-student-item-7-1-description-4": "Click to clear browsing data.",
            "help-page-student-item-7-1-description-5": "Restart Chrome.",
            "help-page-student-item-7-2": "Edge",
            "help-page-student-item-7-2-description-1": "Menu icon › Settings › Padlock button (Privacy and security)",
            "help-page-student-item-7-2-description-2": "Choose items to delete button",
            "help-page-student-item-7-2-description-3": "Check Cached files and data",
            "help-page-student-item-7-2-description-4": "Clear button",
            "help-page-student-item-8-description-1": "Click on this icon to get more information about the training capsule.",
            "question": "Question",
            "meetingReservationPleaseConfirm": "Please confirm your registration request",
            "test": "OK",
            "business-line-filter-name": "Product",
            "business-line-filter-contract-responsability": "Contract",
            "filter-message-1": "For a course that fits your needs, choose your ",
            "filter-message-2": " in the list below :",
            "filter-message-3": "For a route that meets your needs, select the ",
            "filter-message-4": " that applies to your situation:",
            "selected": " selected",
            "filter-placeholder": "Select a ",
            "course-choice-filter-name": "Course choice",
            "course-statement-filter-name": "Choice of statement",
            "course-statement-filter-desc": "statement",
            "course-statement-filter-desc-2": "statement"
        }
    },
    fr: {
        translation: {
            "menu-home": "Accueil",
            "menu-courses": "Parcours",
            "menu-contact": "Nous joindre",
            "menu-help": "Info",
            "menu-search-placeholder": "Recherchez un parcours...",
            "footer-address": "Adresse",
            "footer-phone": "Téléphone",
            "footer-email": "Courriel",
            "footer-short-links": "Liens Rapides",
            "footer-info": "Informations",
            "footer-my-account": "Mon compte",
            "footer-home": "Accueil",
            "footer-courses": "Parcours",
            "footer-contact": "Nous joindre",
            "footer-profile": "Profil",
            "footer-all-right-reserved": "© Intact Compagnie d’assurance, 2023. Tous droits réservés.",
            "login-page-welcome": "Bienvenue",
            "login-page-chose-login-type": "Choisissez par quel type de connexion vous voulez vous connecter",
            "login-page-login-type-intact": "Employé Intact ou Belairdirect",
            "login-page-login-type-externe": "Connexion Externe (ICITÉ)",
            "login-page-login-type-externe-portal": "Connexion Externe (Portail Intact)",
            "login-page-return-btn": "Retour",
            "login-page-login-to-start": "Connectez-vous pour commencer votre session d'apprentissage",
            "login-page-form-email-label": "Adresse Courriel",
            "login-page-form-email-req-message": "SVP entrez votre adresse courriel!",
            "login-page-form-email-invalid-message": "Ceci n'est pas une adresse courriel valide!",
            "login-page-form-password-label": "Mot de passe",
            "login-page-form-password-req-message": "SVP entrez votre mot de passe!",
            "login-page-form-remember-me": "Se rappeler de moi",
            "login-page-form-password-forgot-btn": "Mot de passe oublié",
            "login-page-form-login-btn": "Connecter",
            "forgot-password-page-title": "Mot de passe oublié ?",
            "forgot-password-page-info": "Entrez l'adresse courriel associée à votre compte Icité",
            "forgot-password-page-form-email-label": "Adresse Courriel",
            "forgot-password-page-form-email-req-message": "SVP entrez votre adresse courriel!",
            "forgot-password-page-form-email-invalid-message": "Ceci n'est pas une adresse courriel valide!",
            "forgot-password-page-form-submit-btn": "Envoyer",
            "forgot-password-page-form-submit-success": "Un courriel pour réinitialiser le mot de passe vous a été envoyé",
            "reset-password-page-title": "Réinitialiser votre mot de passe",
            "create-password-page-title": "Création de votre mot de passe",
            "reset-password-page-info": "Entrez votre nouveau mot de passe",
            "reset-password-page-form-password-label": "Mot de passe",
            "reset-password-page-form-password-req-message": "SVP entrez votre mot de passe!",
            "reset-password-page-form-password-confirm-label": "Confirmation Mot de passe",
            "reset-password-page-form-password-confirm-placeholder": "Confirmer mot de passe",
            "reset-password-page-form-password-confirm-req-message": "SVP confimez le mot de passe!",
            "reset-password-page-form-password-two-not-the-same": "Deux mots de passe que vous entrez sont incohérents!",
            "reset-password-page-form-submit-btn": "Envoyer",
            "reset-password-page-form-submit-success": "Votre mot de passe a été réinitilisé avec succès",
            "reset-password-page-form-login": "Se connecter",
            "reset-password-page-request-not-valid": "Cette requête n'est pas valide",
            "create-password-page-form-password-8caracters": "Le mot de passe doit comporter au moins 8 caractères.",
            "create-password-page-form-password-1capitalize": "Le mot de passe doit contenir au moins une lettre majuscule.",
            "create-password-page-form-password-1specialcaracter": "Le mot de passe doit contenir au moins un caractère spécial.",
            "home-page-course-access-btn": "Accéder aux parcours",
            "home-page-advice-title": "Conseils pour un parcours réussi",
            "home-page-advice-subtitle": "QUELQUES EXEMPLES POUR VOUS.",
            "home-page-advice-narration-title": "Narration",
            "home-page-advice-narration-text": "Utilisez des écouteurs, car les formations contiennent des narrations sonore.",
            "home-page-advice-screens-title": "Deux Écrans",
            "home-page-advice-screens-text": "Utilisez vos 2 écrans d’ordinateur afin de projeter la formation sur l’un deux et ouvrir les documents requis sur l’autre.",
            "home-page-advice-environnement-title": "Environnement",
            "home-page-advice-environnement-text": "Mentionnez à vos collègues que vous êtes en formation pour garder le focus.",
            "home-page-advice-teams-title": "Teams",
            "home-page-advice-teams-text": "Si possible, affichez un statut “ne pas déranger” dans Teams pendant votre formation.",
            "home-page-courses-title": "Parcours disponibles",
            "home-page-courses-no-available-courses": "Aucun parcours de disponible",
            "home-page-courses-no-available-courses-contact": "Veuillez contacter l'équipe de Formation et Développement pour vous ajouter aux formations désirées",
            "home-page-see-more-btn": "VOIR PLUS",
            "calendar-date-from": "de",
            "calendar-date-to": "à",
            "calendar-date-at": "à",
            "course-list-page-title": "Liste des parcours",
            "course-list-page-search-placeholder": "Quelle est votre recherche ? (Nom de parcours ou Mots clés)",
            "course-list-page-search-results": "Résultats de recherche",
            "course-list-page-no-search-results": "Aucun résultat pour la recherche",
            "course-list-page-no-available-courses-broker": "Aucun parcours  de disponible pour le moment. Vos parcours seront disponibles plus tard durant l'année 2021",
            "course-list-page-no-available-courses": "Aucun parcours de disponible",
            "course-list-page-no-available-courses-contact": "Veuillez contacter l'équipe de Formation et Développement pour vous ajouter aux parcours désirées",
            "course-list-page-recent-courses-viewed": "Récemment consultés",
            "course-list-advanced-search": "Recherche dans les modules",
            "contact-page-title": "NOUS JOINDRE",
            "contact-page-write-us": "Contactez-nous",
            "contact-page-write-us-subtitle": "TOUJOURS PRÊTS À VOUS RÉPONDRE",
            "contact-page-form-subject": "Sujet",
            "contact-page-form-message": "Message",
            "contact-page-form-send-message": "Envoyer votre message",
            "contact-page-form-send-message-success": "Message envoyé avec succès !",
            "contact-page-form-send-message-fail": "Une erreur est survenue. Le message n'a pas pu être envoyé",
            "help-page-title": "INFO",
            "help-page-write-us": "Écrivez-nous",
            "help-page-write-us-subtitle": "TOUJOURS PRÊTS À VOUS RÉPONDRE",
            "help-page-form-subject": "Sujet",
            "help-page-form-message": "Message",
            "help-page-form-send-message": "Envoyer votre message",
            "help-page-form-send-message-success": "Message envoyé avec succès !",
            "help-page-form-send-message-fail": "Une erreur est survenue. Le message n'a pas pu être envoyé",
            "help-page-student-title": "Informations utiles à propos de la plateforme Icité et quelques conseils pour une expérience optimale",
            "help-page-student-sub-title": "Icité offre une expérience unique d'apprentissage à l'étudiant.",
            "help-page-student-item-1": "Qu'est-ce que Icité ?",
            "help-page-student-item-2": "Comment suivre ma progression ?",
            "help-page-student-item-3": "Comment ajouter des notes personnelles ?",
            "help-page-student-item-4": "Comment télécharger mes documents ?",
            "help-page-student-item-5": "Pourquoi mon activité n'est pas complétée ?",
            "help-page-student-item-6": "Comment réussir mon examen ?",
            "help-page-student-item-7": "Comment vider l'historique de navigation ?",
            "help-page-student-item-8": "Quelles sont les autres informations auxquelles prêter attention ?",
            "help-page-student-item-10": "",
            "help-page-student-item-11": "",
            "help-page-student-item-and-more": "",
            "help-page-student-item-contact": "Si vous avez quelconque question ou avez besoin de support n'hésitez pas à contacter l'équipe de Formation et Développement Intact à l'aide de la page Contact",
            "help-page-student-item-bouton-contact": "Nous écrire",
            "profil-page-profil-type": "Type de profil",
            "profil-page-affiliation": "Affiliation",
            "profil-page-member-since": "Membre depuis",
            "profil-page-modify-password": "Modifier votre mot de passe",
            "profil-page-create-password": "Créer ou modifier votre mot de passe",
            "profil-page-logout": "Se déconnecter",
            "profil-reset-password-page-title": "Changement de mot de passe",
            "profil-reset-password-page-return-btn": "Retour",
            "profil-reset-password-page-password-info": "Si vous n'avez pas de mot de passe, utilisez la section ci-dessous pour le créer et avoir la possibilité de vous connecter sur quelconque appareil personnel.",
            "profil-reset-password-page-password-label": "Mot de passe",
            "profil-reset-password-page-password-req-message": "SVP entrez votre mot de passe!",
            "profil-reset-password-page-confirm-password-label": "Confirmation Mot de passe",
            "profil-reset-password-page-confirm-password-placeholder": "Confirmer mot de passe",
            "profil-reset-password-page-confirm-password-req-message": "SVP confimez le mot de passe!",
            "profil-reset-password-page-form-password-two-not-the-same": "Deux mots de passe que vous entrez sont incohérents!",
            "profil-reset-password-page-send": "Envoyer",
            "profil-reset-password-page-reset-success": "Votre mot de passe a été réinitilisé avec succès",
            "profil-reset-password-page-return-to-profile": "Retour au profil",
            "course-intro-page-slides-number": "capsules",
            "course-intro-page-start-btn": "Commencer",
            "course-intro-page-course-content-btn": "Contenu du parcours",
            "course-intro-page-course-content-title": "Contenu du parcours",
            "course-intro-page-course-documents-btn": "Documents",
            "course-intro-page-course-documents-title": "Documents du parcours",
            "course-intro-page-course-documents-cohorte-title": "Documents de cohorte",
            "course-intro-page-course-no-documents-available": "Aucun documents disponible",
            "course-intro-page-course-virtual-meeting-required": "Rencontre virtuelle requise !",
            "course-intro-page-course-virtual-meeting-suggested-optionnal": "Webinaire optionnel suggéré !",
            "course-intro-page-course-virtual-meeting-suggested": "Incription au webinaire !",
            "course-intro-page-course-virtual-meeting-confirmed": "Inscription complétée avec succès !",
            "course-intro-page-course-virtual-meeting-required-btn": "M'inscrire",
            "course-intro-page-course-virtual-meeting-suggested-btn": "M'inscrire",
            "course-intro-page-course-virtual-meeting-btn-label": "M'inscrire",
            "course-intro-page-course-virtual-meeting-continue-btn-label": "Continuer",
            "course-intro-page-course-virtual-meeting-calendar-btn": "Calendrier",
            "course-intro-page-course-virtual-meeting-list-btn": "Liste",
            "course-play-page-return-btn": "Retour",
            "course-play-page-progress-done": "terminé",
            "course-play-page-search-btn-label": "Rechercher une capsule",
            "course-play-page-documents-btn-label": "Documents du parcours",
            "course-play-page-welcome": "Bienvenue !",
            "course-play-page-welcome-start-btn": "Commencer",
            "course-play-page-welcome-download-documents-btn": "Téléchargez les documents",
            "course-play-page-addition-information-title": "Information additionnelle",
            "course-play-page-menu-prerequis-text-module-label": "Capsules",
            "course-play-page-menu-prerequis-text": "Les capsules listées ci-dessous (voir numéro), doivent être complétées avant d'accéder à celle-ci.",
            "course-play-page-course-end": "Fin du parcours",
            "course-play-page-course-end-congrats": "Félicitations !",
            "course-play-page-course-end-congrats-done": "Vous avez terminé le parcours",
            "course-play-page-course-end-congrats-done-scorm": "Félicitations vous avez terminé la formation",
            "course-play-modules-prev-btn": "Précédent",
            "course-play-modules-next-btn": "Suivant",
            "course-play-modules-confirm-choice-btn": "Confirmer le choix",
            "course-play-modules-next-task-btn": "Aller à la prochaine activité",
            "course-play-modules-return-to-menu-btn": "Fin du parcours (Retour au menu)",
            "course-play-modules-replay-video-btn": "Rejouer la vidéo",
            "course-play-modules-next-question-btn": "Prochaine question",
            "course-play-modules-good-answer": "Bonne réponse!",
            "course-play-modules-wrong-answer": "Mauvaise réponse",
            "course-play-modules-wrong-answer-chose-another": "Veuillez choisir une autre réponse",
            "course-play-modules-your-score": "Votre Score",
            "course-play-modules-attempts": "Tentatives",
            "course-play-modules-attempts-tries": "fois",
            "course-play-modules-explanation": "Explication",
            "course-play-modules-update-my-answer": "Mettre à jour ma réponse",
            "course-play-modules-compare-with-good-answer": "Comparez avec la bonne réponse",
            "course-play-modules-only-first-attempt-counts": "Seule la première tentative de chaque question compte",
            "course-play-modules-only-last-answer-of-each-question-is-saved": "Seule la denière réponse de chaque question sera enregistrée",
            "course-play-modules-click-below-button-to-skip-question": "Cliquez sur le bouton ci-dessous pour passer à la prochaine question",
            "course-play-modules-click-below-button-to-skip-task": "Cliquez sur le bouton ci-dessous pour passer à la prochaine activité",
            "course-play-modules-stats-percentage-result": "Résultat",
            "course-play-modules-stats-percentage-score": "Score",
            "course-play-modules-you-suceeded": "Vous avez réussi !",
            "course-play-modules-congrats-points": "Félicitations! Vous avez teminé avec un pointage total de",
            "course-play-modules-congrats-skip-and-continue": "Cliquez sur le bouton ci-dessous pour continuer à répondre aux questions restantes",
            "course-play-modules-unfortunate-fail-points": "Dommage! Vous avez teminé avec un pointage total de",
            "course-play-modules-close-window": "Fermer cette fenêtre",
            "course-play-modules-close-exercice-keep-on-going": "Cliquez sur le bouton ci-dessous pour voir la rétroaction de la dernière question",
            "course-play-modules-exam-intruction-title": "INSTRUCTIONS",
            "course-play-modules-exam-intruction-passing-score": "La note de passage est de",
            "course-play-modules-exam-intruction-only-first-attempt": "Seule la première tentative complétée compte et sera enregistrée",
            "course-play-modules-exam-intruction-you-have": "Vous avez",
            "course-play-modules-exam-intruction-you-have-hours": "heure(s) pour compléter cet examen.",
            "course-play-modules-exam-intruction-you-have-hour": "heure pour compléter cet examen.",
            "course-play-modules-exam-intruction-no-save": "Si vous sortez de ce panneau, l'examen ne sera pas enregistré",
            "course-play-modules-exam-intruction-when-done-submit": "Lorsque vous aurez terminé, appuyez sur le bouton 'soumettre' afin de sauvegarder vos résultats",
            "course-play-modules-exam-intruction-revision-btn": "Révision tentative",
            "course-play-modules-exam-intruction-start-btn": "Pour débuter votre examen, Cliquez ici",
            "course-play-modules-exam-intruction-good-luck": "Bonne chance !",
            "course-play-modules-exam-submit-btn": "Soumettre l'examen",
            "course-play-modules-exam-end-success": "Vous avez réussi !",
            "course-play-modules-exam-end-fail": "Vous avez échoué",
            "course-play-modules-exam-end-passing-score": "La note de passage est de",
            "course-play-modules-exam-end-your-score": "Votre note finale est de",
            "course-play-modules-exam-remaining-time": "Temps Restant",
            "course-play-modules-exam-time-ended": "Temps terminé!",
            "course-play-modules-exercice-ended-congrats": "Félicitations, vous avez maintenant complété l'exercice.",
            "course-play-modules-exercice-ended-score": "Votre score est",
            "course-play-modules-select": "Sélectionnez un ",
            "our-numbers-title": "Quand nos chiffres parlent d'eux mêmes",
            "our-numbers-students": "Étudiants inscrits",
            "our-numbers-certificates": "Certificats attribués",
            "our-numbers-trainings": "Parcours offerts en ligne",
            "start-now-title": "Commencez dès maintenant",
            "start-now-subtitle": "Cliquez sur le bouton ci-dessous pour voir la liste des formations",
            "start-course-access-btn": "Accéder aux parcours",
            "course-preview-read-more": "Lire plus",
            "course-preview-read-less": "Lire moins",
            "course-preview-see-course": "Voir le parcours",
            "course-student-guide-pdf-main-title-1": "Guide d'étudiant (Notes et Documents)",
            "course-student-guide-pdf-loading": "Chargement du Guide Étudiant...",
            "course-student-guide-pdf-download": "Télécharger Guide Étudiant (Notes et Documents)",
            "general-texts-explication": "Explication",
            "general-texts-consigne": "Consigne",
            "autoEvaluation1ModuleConfirmChoice": "Confirmer le choix et",
            "autoEvaluation1ModuleGoToNextQuestion": "passer à la prochaine question",
            "calculationModuleYourAnswer": "Votre réponse",
            "calculationModuleGoodAnswer": "La bonne réponse est",
            "calculationModuleConfirm": "Confirmer",
            "moreQuestionsYesOrNoYes": "Oui",
            "moreQuestionsYesOrNoNo": "Non",
            "overviewExamplesQuestions": "Exemples de questions",
            "satisfactionVeryDissatisfied": "Très insatisfait(e)",
            "satisfactionDissatisfied": "Insatisfait(e)",
            "satisfactionSatisfied": "Satisfait(e)",
            "satisfactionNeutral": "Neutre",
            "satisfactionVerySatisfied": "Très satisfait(e)",
            "coursePreviewAccessStart": "Début d'accès : ",
            "coursePreviewAccessEnd": "Fin d'accès : ",
            "dashboardContainerFirstVisit": "Première visite ?",
            "dashboardContainerMessageFirstVisit": "Si ceci est votre première visite n'hésitez pas à visiter la page INFO pour en savoir plus sur la plateforme Icité et apprendre comment bien utiliser les différentes fonctionnalités.",
            "dashboardContainerVisitInfoPage": "Consulter la page INFO",
            "close": "Fermer",
            "dashboardContainerStartAccessError": "Vous n'avez pas encore accès.\n Votre accès commmencera à partir de cette date: \n",
            "dashboardContainerEndAccessError": "Vous n'avez plus accès.\n Votre accès a pris fin à cette date: \n",
            "dashboardContainerWarningBetterExperience": "Pour une meilleure expérience veuillez copier le lien et ouvrir le site sur le navigateur Chrome",
            "dashboardContainerHaveAGoodSession": "Passez une bonne séance de formation.",
            "dashboardContainerGoodNight": "Bonne nuit",
            "dashboardContainerGoodMorning": "Bon matin",
            "dashboardContainerHello": "Bonjour",
            "dashboardContainerGoodMorning2": "Bon avant-midi",
            "dashboardContainerGoodLunch": "Bon diner",
            "dashboardContainerGoodAfterNoon": "Bon après-midi",
            "dashboardContainerGoodEvening": "Bonne soirée",
            "dashboardContainerFr/Eng": "EN",
            "dashboardContainerLogout": "Se déconnecter",
            "dustbinRelease": "Relâchez",
            "dustbinDragYourChoice": "Glissez votre choix de réponse ici",
            "examModulePinLater": "Épingler pour plus tard",
            "examModuleUnpin": "Dépingler",
            "examModulePin": "Épingler",
            "examModuleSubmitExam": "Soumettre l'examen",
            "examModuleSubmitExamExplination": "Cliquez sur le bouton Soumettre si vous êtes certain d'avoir compléter l'examen et que vous voulez l'envoyer",
            "examModuleCancel": "Annuler",
            "examModuleSubmit": "Soumettre",
            "examMultipleChoiceModulePinLater": "Épingler pour plus tard",
            "examMultipleChoiceModuleUnPin": "Dépingler",
            "examMultipleChoiceModulePin": "Épingler",
            "examMultipleChoiceModuleSubmitExam": "Soumettre l'examen",
            "examMultipleChoiceModuleSubmitExamExplanation": "Cliquez sur le bouton Soumettre si vous êtes certain d'avoir compléter l'examen et que vous voulez l'envoyer",
            "examMultipleChoiceModuleCancel": "Annuler",
            "examMultipleChoiceModuleSubmit": "Soumettre",
            "exerciceModuleWarning": "Avertissement",
            "exerciceModuleIsTimed": "Cet exercice est chronométré",
            "exerciceModuleIsTimedExplnation1": "Vous aurez",
            "exerciceModuleIsTimedExplnation2": " min par question. Si le temps se termine, la réponse sera considérée incorrecte",
            "exerciceModuleStart": "Commencer",
            "exerciceModuleTimeEnded": "Le temps est terminé !",
            "exerciceOpenQuestionModuleChoice": "Veuillez choisir un des choix au dessus avant d'écrire une réponse dans ce champs",
            "exerciceOpenQuestionModuleEntrerYourAnswer": "Entrez votre réponse ici",
            "exerciceOpenQuestionConfirm": "Confirmer la réponse et",
            "exerciceOpenQuestionModulegoNextQuestion": "passer à la prochaine question",
            "coursePlayClickOnIcon": "Cliquez sur cette icône pour ouvrir le menu",
            "coursePlayClose": "Fermer",
            "documentSlideCntDocumentsDownload": "Documents à télécharger",
            "meetingReservationConfirmUnSubscription": "Veuillez confirmer la désinscription à la rencontre",
            "meetingReservationConfirm": "Confirmer",
            "meetingReservationCancel": "Annuler",
            "meetingReservationUnsubscriptionSuccess": "Désinscription effectuée avec succès à la rencontre",
            "meetingReservationClose": "Fermer",
            "meetingReservationMandatoryMeeting1": "Pour continuer votre parcours, vous devez tout d'abord participer à une rencontre virtuelle.",
            "meetingReservationMandatoryMeeting2": "Veuillez choisir une rencontre qui vous convient ci-dessous selon votre horaire.",
            "meetingReservationInscriptionDone": "Vous êtes maintenant inscrit(e) à ce Webinaire, une invitation vous a été envoyée par courriel afin de l'ajouter à votre agenda.",
            "meetingReservationTeamsLinkAvailable": "Le lien Teams sera disponible 30 min avant le début de la rencontre.",
            "meetingReservationMeetingTime": "Date et heure de la rencontre",
            "meetingReservationPresenceConfirm": "Votre présence à la rencontre a été confirmée !",
            "meetingReservationClickOnLinkToAccessMeeting": "Veuillez cliquer sur le lien ci-dessous pour accéder à la rencontre :",
            "meetingReservationlinkUnavailable": "Lien non disponible pour le moment.",
            "meetingReservationClickToParticipateWebinar": "Cliquez ici pour participer au webinaire",
            "meetingReservationClickToParticipateMeeting": "Cliquez ici pour participer à la rencontre",
            "meetingReservationConfirmNowregistered1": "Vous êtes maintenant inscrit(e) à",
            "meetingReservationConfirmNowregistered2": "ce Webinaire",
            "meetingReservationConfirmNowregistered3": "cette rencontre",
            "meetingReservationConfirmNowregistered4": ", une invitation vous a été envoyée par courriel afin de l'ajouter à votre agenda.",
            "meetingReservationConfirmNowregistered5": "Le lien Teams sera disponible 30 min avant le début de la rencontre.",
            "meetingReservationIsConfirmActiveDate": "Date et heure de rencontre",
            "meetingReservationIsConfirmActiveEditMeeting": "Modifier la rencontre choisie",
            "meetingReservationIsConfirmActiveUnsubscribe": "Me désinscrire",
            "meetingReservationThanskForParticipationWebinar": "Merci d'avoir assisté au webinaire qui a eu lieu le ",
            "meetingReservationThanskForParticipationMeeting": "Merci d'avoir assisté à la rencontre qui a eu lieu le ",
            "meetingReservationToModifyMeeting": "Pour modifier la rencontre choisie...",
            "meetingReservationToSubscribe": "Pour vous inscrire...",
            "meetingReservationIsRencontreAmbassadeursSelectDate": "Sélectionnez la date et l'heure dans la liste ci-dessous :",
            "meetingReservationPlaceholder": "Sélectionnez une rencontre ↓",
            "meetingReservationCancelModification": "Annuler la modification",
            "meetingReservationMeeting": "La rencontre",
            "meetingReservationTakePlace": "aura lieu",
            "meetingReservationSubscribeToMeeting": "S'inscrire à cette rencontre",
            "meetingReservationEndBloc5Part1": "À la fin du bloc 5 vous pouvez vous inscrire au",
            "meetingReservationEndBloc5Part2": "Webinaire optionnel : Retour sur les notions plus avancées de Contact AP",
            "meetingReservationNoMeetingAvailable": "Aucune rencontre disponible pour le moment !",
            "meetingReservationWaitAMeetingTryAgain": "Veuillez patienter qu'une rencontre soit disponible et essayer à nouveau.",
            "meetingReservationContinueWithoutTakingAMeeting": "Pour continuer le parcours sans participer à une rencontre virtuelle, veuillez cliquer sur le bouton ci-dessous :",
            "meetingReservationContinue": "Continuer",
            "meetingReservationSubscribeLater": "Je ne souhaite pas m'inscrire maintenant",
            "meetingReservationContinueWithSubscribeLater": "Cliquez ci-dessous pour poursuivre le parcours sans vous inscrire, vous pourrez y revenir plus tard.",
            "meetingReservationContinueTraining": "Poursuivre le parcours",
            "error": "Une Erreur s’est produite",
            "help-page-student-item-1-description-1": "Icité est une plateforme utilisée pour les parcours d'intégration et de changement de niveau, comprenant :",
            "help-page-student-item-1-description-2": "Design simple, attrayant et ergonomique.",
            "help-page-student-item-1-description-3": "Navigation facile et intuitive.",
            "help-page-student-item-1-description-4": "Système de progression pour une bonne continuité de parcours.",
            "help-page-student-item-1-description-5": "Multiples modules intégrés (exercices, examens, vidéos, etc.)",
            "help-page-student-item-2-description-1": "Suivez votre progression globale dans le parcours grâce à l'indication en pourcentage :",
            "help-page-student-item-2-description-2": "Faites les activités dans l'ordre proposé. Le menu de gauche vous permet de consulter la liste des modules du parcours.",
            "help-page-student-item-2-description-3": "Pour certaines activités, un résultat s'affiche.",
            "help-page-student-item-2-description-4": "Conseil",
            "help-page-student-item-2-description-5": "Dans votre progression, refaites les exercices les moins bien réussis.",
            "help-page-student-item-3-description-1": "Les notes personnelles ne sont visibles que par vous uniquement.",
            "help-page-student-item-3-description-2": "Les notes sont enregistrées automatiquement quand vous fermez la fenêtre.",
            "help-page-student-item-3-description-3": "Conseil",
            "help-page-student-item-3-description-4": " Notez vos questions en prévision de la prochaine intervention/classe virtuelle avec votre formateur.",
            "help-page-student-item-5-description-1": "Assurez-vous de bien terminer chacune des activités (par exemple, utilisation du bouton",
            "help-page-student-item-5-description-2": "Quitter le module",
            "help-page-student-item-5-description-3": "ou",
            "help-page-student-item-5-description-4": "Sortie",
            "help-page-student-item-5-description-5": ", cliquer sur",
            "help-page-student-item-5-description-6": "Aller à la prochaine activité",
            "help-page-student-item-5-description-7": "Soumettre",
            "help-page-student-item-5-description-8": " J'ai terminé la saisie !",
            "help-page-student-item-6-description-1": "Conseil",
            "help-page-student-item-6-description-2": "Épingler les questions pour y revenir plus tard afin d'affecter au minimum le temps d'examen.",
            "help-page-student-item-7-description-1": "Épingler les questions pour y revenir plus tard afin d'affecter au minimum le temps d'examen.",
            "help-page-student-item-7-description-2": "",
            "help-page-student-item-7-description-3": "",
            "help-page-student-item-7-1": "Chrome",
            "help-page-student-item-7-1-description-1": "Icône Menu › Paramètres › Paramètres avancés › Confidentialité et sécurité - Effacer les données de navigation...",
            "help-page-student-item-7-1-description-2": "Période : Toutes les périodes.",
            "help-page-student-item-7-1-description-3": "Cocher Images et fichiers en cache.",
            "help-page-student-item-7-1-description-4": "Cliquer effacer les données de navigation.",
            "help-page-student-item-7-1-description-5": "Redémarrer Chrome.",
            "help-page-student-item-7-2": "Edge",
            "help-page-student-item-7-2-description-1": "Icône Menu › Paramètres › bouton Cadenas (Confidentialité et sécurité).",
            "help-page-student-item-7-2-description-2": "Bouton Choisissez les éléments à effacer.",
            "help-page-student-item-7-2-description-3": "Cocher Fichiers et données en cache.",
            "help-page-student-item-7-2-description-4": "Bouton Effacer.",
            "help-page-student-item-8-description-1": "Cliquez sur cette icône pour obtenir plus d'informations au sujet de la capsule.",
            "question": "Question",
            "meetingReservationPleaseConfirm": "Veuillez confirmer la demande d'inscription",
            "business-line-filter-name": "Produit",
            "business-line-filter-contract-responsability": "Contrat",
            "filter-message-1": "Pour un parcours répondant à vos besoins, choisissez votre",
            "filter-message-2": " dans la liste ci-dessous:",
            "filter-message-3": "Pour un parcours répondant à vos besoins, sélectionnez ",
            "filter-message-4": "qui s’applique à votre situation :",
            "selected": " sélectionné",
            "filter-placeholder": "Sélectionnez un ",
            "course-choice-filter-name": "Choix de cours",
            "course-statement-filter-name": "Choix de l'énoncé",
            "course-statement-filter-desc": "l'énoncé ",
            "course-statement-filter-desc-2": "énoncé"

        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: Cookies.get('lang') ? Cookies.get('lang') : 'fr',
        fallbackLng: Cookies.get('lang') ? Cookies.get('lang') : 'fr', // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;